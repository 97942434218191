<template>
  <footer>
    <p>Powered by <a
        href="https://github.com/CokeMine/ServerStatus-Hotaru">ServerStatus-Hotaru</a></p>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
footer p {
  text-align: center;
  padding-bottom: 12px;
}
</style>